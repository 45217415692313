import { mergeStyleSets } from "@fluentui/react";

export interface BetsPageStyles {
  root: string;
  navigationPanel: string;
  contentWrapper: string;
}

export const getClassNames = (): BetsPageStyles => {
  return mergeStyleSets({
    root: {
      display: 'flex',
      height: '100%',
      marginLeft: 8,
    },
    navigationPanel: {
      height: '100%',
      borderRight: '1px solid #d0d0d0',
      backgroundColor: '#fbfbfd',
    },
    contentWrapper: {
      flexGrow: 1,
      marginLeft: 12,
    },
  });
};