import { mergeStyleSets } from "@fluentui/react";

export interface AppStyles {
  container: string;
  content: string;
}

export const getClassNames = (): AppStyles => {
  return mergeStyleSets({
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    content: {
      flexGrow: 1,
      overflowY: 'auto',
    }
  });
};