import { useState } from 'react';
import React from 'react';
import { getClassNames } from './ImageSetStyles';
import { DirectionalHint, IconButton, TooltipHost } from '@fluentui/react';

interface ImageSetProps {
  paths: string[];
}

export const ImageSet: React.FC<ImageSetProps> = (props: ImageSetProps) => {
  const classNames = getClassNames();

  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);

  const beforeChevronProps = { iconName: 'ChevronLeft' };
  const nextChevronProps = { iconName: 'ChevronRight' };

  const onPrevious = () => {
    let newIndex = selectedImageIndex - 1;

    if (newIndex < 0) {
      const imageCount = props.paths.length;
      newIndex = imageCount - 1;
    }

    setSelectedImageIndex(newIndex);
  };

  const onNext = () => {
    let newIndex = selectedImageIndex + 1;
    const imageCount = props.paths.length;

    if (newIndex >= imageCount) {
      newIndex = 0;
    }

    setSelectedImageIndex(newIndex);
  };

  const tooltip = (
    <div>
      <img src={props.paths[selectedImageIndex]} alt={'The pic'} width={400} height={300} />
      <div>
        <IconButton iconProps={beforeChevronProps} onClick={onPrevious} />
        <IconButton iconProps={nextChevronProps} onClick={onNext} />
      </div>
    </div>
  );

  return (
    <div>
      <TooltipHost className={classNames.root} directionalHint={DirectionalHint.topLeftEdge} content={tooltip} >
        <img src={props.paths[selectedImageIndex]} alt={'The pic'} width={300} height={200} />
      </TooltipHost>
      <div>
        <IconButton iconProps={beforeChevronProps} onClick={onPrevious} />
        <IconButton iconProps={nextChevronProps} onClick={onNext} />
      </div>
      {/* <div className={classNames.root}>
        <img src={props.paths[selectedImageIndex]} alt={'The pic'} width={300} height={200} />
        <div>
          <IconButton iconProps={beforeChevronProps} onClick={onPrevious} />
          <IconButton iconProps={nextChevronProps} onClick={onNext} />
        </div>

        
        <div className={classNames.child}>
          <img src={props.paths[selectedImageIndex]} alt={'The pic'} width={400} height={300} />
          <div>
            <IconButton iconProps={beforeChevronProps} onClick={onPrevious} />
            <IconButton iconProps={nextChevronProps} onClick={onNext} />
          </div>
        </div>
      </div> */}
    </div>
  );
};