import { Link } from '@fluentui/react';
import { Link as RouterLink } from 'react-router-dom';
import { getClassNames } from './MenuStyles';

export const Menu: React.FC = () => {
  const classNames = getClassNames();

  return (
    <header className={classNames.header}>
      <Link as={RouterLink} className={classNames.menuItem} to={'/australia'}>L&M</Link>
      <nav>
        <menu className={classNames.menu}>
          <li>
            <Link as={RouterLink} className={classNames.menuItem} to={'/australia'}>My blog</Link>
          </li>
          <li>
            <Link as={RouterLink} className={classNames.menuItem} to={'/bets'}>My bets</Link>
          </li>
          <li>
            <Link as={RouterLink} className={classNames.menuItem} to={'/wall'}>My love wall</Link>
          </li>
        </menu>
      </nav>
    </header>
  );
};