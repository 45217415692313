import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { getClassNames } from './AppStyles';
import { BetsCenter } from './components/Bets/BetsCenter';
import { BetsPage } from './components/Bets/BetsPage';
import { BetsReport } from './components/Bets/BetsReport';
import { Gallery } from './components/Gallery/Gallery';
import { Menu } from './components/Menu';
import { WallPage } from './components/Wall/WallPage';
import { PageProviders } from './providers/PageProviders';

export const App: React.FunctionComponent = () => {
  const classNames = getClassNames();

  return (
    <div className={classNames.container}>
      <BrowserRouter>
        <Menu />
        <div className={classNames.content}>
          <Routes>
            <Route path="/" element={<PageProviders><Gallery /></PageProviders>} />
            <Route path="australia" element={<PageProviders><Gallery /></PageProviders>} />
            <Route path="bets" element={<BetsPage />}>
              <Route index element={<BetsCenter />} />
              <Route path="play" element={<BetsCenter />} />
              <Route path="report" element={<BetsReport />} />
            </Route>
            <Route path="wall" element={<WallPage />} />
            <Route path="*" element={<div>Wrong path</div>}>
            </Route>
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
};
