import { FontIcon, IColumn, SelectionMode, ShimmeredDetailsList } from "@fluentui/react";

enum BetStatus {
  Ongoing,
  Lost,
  Won
}

interface IBet {
  id: string;
  eventStartTime: string;
  gameDisplayName: string;
  choiceDisplayName: string;
  odds: number;
  bet: number;
  gain: number;
  status: BetStatus;
}

// const iconClass = mergeStyles({
//   fontSize: 25,
//   height: 25,
//   width: 25,
//   margin: '0 10px',
// });

// const classNames = mergeStyleSets({
//   red: [{ color: 'red' }, iconClass],
//   yellow: [{ color: 'grey' }, iconClass],
//   green: [{ color: 'green' }, iconClass],
// });

const statusToIcon = (betStatus: BetStatus) => {
  switch (betStatus) {
    case BetStatus.Lost:
      return <FontIcon aria-label="BoxMultiplySolid" iconName="BoxMultiplySolid" />;
    case BetStatus.Ongoing:
      return <FontIcon aria-label="BoxSubtractSolid" iconName="BoxSubtractSolid" />;
    case BetStatus.Won:
      return <FontIcon aria-label="BoxCheckmarkSolid" iconName="BoxCheckmarkSolid" />;
    default:
      throw new Error('Unhandled bet status: ' + betStatus);
  }
}

const betValues: IBet[] = [
  { id: '0', gameDisplayName: 'Olympique de Marseillle - Paris Saint Germain', eventStartTime: 'Ongoing', choiceDisplayName: 'Olympique de Marseille gagne dans le temps réglementaire.', odds: 2.85, bet: 10, gain: 0, status: BetStatus.Ongoing },
  { id: '1', gameDisplayName: 'Cleveland Cavaliers - New York Knicks', eventStartTime: '25/12/21', choiceDisplayName: 'New York Knicks gagne à la mi-temps.', odds: 1.85, bet: 10, gain: -10, status: BetStatus.Lost },
  { id: '2', gameDisplayName: 'Djokovic - Nadal', eventStartTime: '07/06/22', choiceDisplayName: 'Nadal gagne en 3 sets.', odds: 1.85, bet: 10, gain: 8.5, status: BetStatus.Won },
];

const betsColumns: IColumn[] = [
  {
    key: 'gameDisplayName',
    fieldName: 'gameDisplayName',
    name: 'Game',
    minWidth: 80,
  },
  {
    key: 'eventStartTime',
    fieldName: 'eventStartTime',
    name: 'Start time',
    minWidth: 80,
  },
  {
    key: 'choiceDisplayName',
    fieldName: 'choiceDisplayName',
    name: 'Choice',
    minWidth: 400,
  },
  {
    key: 'odds',
    fieldName: 'odds',
    name: 'Odds',
    minWidth: 80,
  },
  {
    key: 'bet',
    fieldName: 'bet',
    name: 'Bet',
    minWidth: 80,
  },
  {
    key: 'Gain',
    fieldName: 'gain',
    name: 'Gain',
    minWidth: 80,
  },
  {
    key: 'status',
    name: 'Status',
    onRender: (bet: IBet) => {
      return statusToIcon(bet.status);
    },
    minWidth: 80,
  }
];

//   const columns: IColumn[] = buildColumns(currentItems);
//   for (const column of columns) {
//     if (column.key === 'thumbnail') {
//       column.name = 'FileType';
//       column.minWidth = 16;
//       column.maxWidth = 16;
//       column.isIconOnly = true;
//       column.iconName = 'Page';
//       break;
//     }
//   }
//   return columns;
// });

export const BetsReport: React.FC = () => {
  return <ShimmeredDetailsList
    setKey="betValues"
    items={betValues || []}
    columns={betsColumns}
    selectionMode={SelectionMode.none}
    enableShimmer={!betValues}
    ariaLabelForShimmer="Content is being fetched"
    ariaLabelForGrid="Item details"
  />;
}