import { IconButton, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import React, { useState } from 'react';
import { Memory } from '../../models/memory';
import { MemoryContext } from '../../providers/MemoryProvider';
import { getClassNames } from './MemoryPreviewStyles';

export interface MemoryPreviewProps extends Memory {
}

export const MemoryPreview: React.FC<MemoryPreviewProps> = (props: MemoryPreviewProps) => {
  const classNames = getClassNames();
  const [isBeingDeleted, setIsBeingDeleted] = useState<boolean>(false);

  const { id, pathToImage, label, date, spot } = props;

  const context = React.useContext(MemoryContext);

  const closeButtonProps = { iconName: 'ChromeClose' };

  const deleteMemory = (idToDelete: string) => {
    setIsBeingDeleted(true);
    context.deleteMemory.operation(idToDelete);
  };

  return (
    <div className={classNames.root}>
      {isBeingDeleted ? (
      <Spinner className={classNames.spinner} size={SpinnerSize.large} label={'Deleting the memory...'} />
      ) :
        (<>
          <Stack horizontal>
            <img className={classNames.image} src={pathToImage} alt="preview" width="300" />
            {/* <IconButton iconProps={{ iconName: 'Emoji2' }} title="Emoji" ariaLabel="Emoji" /> */}
            <IconButton iconProps={closeButtonProps} onClick={() => deleteMemory(id)} />
          </Stack>
          <div className={classNames.descriptionContainer}>
            <div className={classNames.title}>{label}</div>
            <div className={classNames.timeLabel}>{date}</div>
            <div className={classNames.locationLabel}>{spot}</div>
          </div>
        </>)
      }
    </div>
  );
};