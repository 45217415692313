import { initializeIcons, mergeStyles } from '@fluentui/react';
import ReactDOM from 'react-dom';
import { App } from './App';
import reportWebVitals from './reportWebVitals';

initializeIcons();

// Inject some global styles
mergeStyles({
  ':global(body,html,#root)': {
    margin: 0,
    padding: 0,
    height: '100vh',
    fontFamily: '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
  },
  ':global(h2)': {
    margin: 0,
    paddingTop: 15,
    paddingBottom: 15,
    fontSize: 24,
    fontWeight: 600,
    color: '#323130',
  },
  ':global(h3)': {
    margin: 0,
    paddingTop: 10,
    paddingBottom: 10,
    fontSize: 20,
    fontWeight: 600,
    color: '#605E5C',
  },
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
