import { mergeStyleSets } from "@fluentui/react";

export interface LineStyles {
    line: string;
}

export const getClassNames = (): LineStyles => {
    return mergeStyleSets({
        line: {
            width: 2,
            backgroundColor: '#d0d0d0',
        }
    });
};