import { Memory } from '../models/memory';

// const wait = (seconds: number) => {
//   return new Promise(resolve => setTimeout(resolve, seconds));
// }

const getMemories: () => Promise<Memory[]> = async () => {
  // TODO Call listMemories API
  return new Promise(resolve => {
    setTimeout(() => {
      const memories = JSON.parse(window.localStorage.getItem('gallery') || '[]');
      resolve(memories);
    }, 1 * 1000);
  });
};

const addMemory = (newMemory: Memory) => {
  // TODO Call addMemory API
  const newMemories: Memory[] = JSON.parse(window.localStorage.getItem('gallery') || '[]');
  newMemories.push(newMemory);
  window.localStorage.setItem('gallery', JSON.stringify(newMemories));
};

const deleteMemory = async (memoryId: string) => {
  // TODO Call addMemory API
  await new Promise(resolve => {
    setTimeout(() => {
      const memories: Memory[] = JSON.parse(window.localStorage.getItem('gallery') || '[]');
      const newMemories = memories.filter(m => m.id !== memoryId);
      window.localStorage.setItem('gallery', JSON.stringify(newMemories));
      resolve(0);
    }, 1 * 1000);
  });
};

export const galleryStore = {
  getMemories,
  addMemory,
  deleteMemory
};