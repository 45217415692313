import { MessageBarType } from '@fluentui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { Memory } from '../models/memory';
import { MessageBarContext } from '../services/MessageBarService';
import { galleryStore } from '../store/galleryStore';

interface Operation<T> {
  operation: T;
  isOngoing: boolean;
}

interface MemoryContextProps {
  memories: Memory[];
  addMemory: Operation<(memory: Memory) => void>;
  deleteMemory: Operation<(memoryId: string) => void>;
  loadMemories: Operation<() => void>;
}

const defaultOperation = { operation: () => { throw new Error('Unreachable code'); }, isOngoing: false };
const defaultContext: MemoryContextProps = { memories: [], addMemory: defaultOperation, deleteMemory: defaultOperation, loadMemories: defaultOperation };

export const MemoryContext = React.createContext<MemoryContextProps>(defaultContext);

export const MemoryProvider: React.FC = ({ children }) => {
  const { setMessageBarProps } = React.useContext(MessageBarContext);
  const [memories, setGallery] = useState<Memory[]>([]);
  const [areMemoriesLoading, setAreMemoriesLoading] = useState<boolean>(false);
  const [isAddMemoryLoading, setIsAddMemoryLoading] = useState<boolean>(false);
  const [isDeleteMemoryLoading, setIsDeleteMemoryLoading] = useState<boolean>(false);

  const getMemories = useCallback(async () => {
    const memories = await galleryStore.getMemories();
    setGallery(memories);
  }, [setGallery]);

  const refreshMemories = useCallback(async () => {
    setAreMemoriesLoading(true);
    await getMemories();
    setAreMemoriesLoading(false);
  }, [setAreMemoriesLoading, getMemories]);

  const loadMemories = {
    operation: refreshMemories,
    isOngoing: areMemoriesLoading
  };

  useEffect(() => {
    refreshMemories();
  }, [refreshMemories]);

  const addMemory = {
    operation: (memory: Memory) => {
      setIsAddMemoryLoading(true);
      galleryStore.addMemory(memory);
      refreshMemories();
      setIsAddMemoryLoading(false);
      setMessageBarProps({messageBarType: MessageBarType.success, children: 'New memory has been added.'});
    },
    isOngoing: isAddMemoryLoading
  };

  const deleteMemory = {
    operation: async (memoryId: string) => {
      setIsDeleteMemoryLoading(true);
      await galleryStore.deleteMemory(memoryId);
      await getMemories();
      setIsDeleteMemoryLoading(false);
      setMessageBarProps({messageBarType: MessageBarType.success, children: 'The memory has been deleted.'});
    },
    isOngoing: isDeleteMemoryLoading
  };

  return (
    <MemoryContext.Provider value={{ memories, addMemory, deleteMemory, loadMemories }}>
      {children}
    </MemoryContext.Provider>
  );
};