import { mergeStyleSets } from "@fluentui/react";

export interface ArrowStyles {
	arrow: string;
	wrapper: string;
	outsideArrow: string;
	insideArrow: string;
}

export const getClassNames = (): ArrowStyles => {
	return mergeStyleSets({
		arrow: {
			border: '12px solid transparent',
			borderRightColor: 'black',
		},
		wrapper: {
			position: 'relative',
			marginLeft: 8,
			minWidth: 8,
		},
		outsideArrow: {
			position: 'absolute',
			marginTop: 11,
			width: 8,
			height: 16,
			backgroundColor: '#d0d7de',
			clipPath: 'polygon(0 50%, 100% 0, 100% 100%)',
		},
		insideArrow: {
			position: 'absolute',
			marginLeft: 2,
			marginTop: 11,
			width: 8,
			height: 16,
			backgroundColor: '#f6f8fa',
			clipPath: 'polygon(0 50%, 100% 0, 100% 100%)',
		},
	});
};