import { INavLink, INavLinkGroup, Nav } from "@fluentui/react";
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { getClassNames } from "./BetsPageStyles";

const navLinkGroups: INavLinkGroup[] = [
  {
    links: [
      {
        key: 'play',
        name: 'Play',
        url: '/bets/play',
        icon: 'Game',
      },
      {
        key: 'results',
        name: 'Results',
        icon: 'News',
        url: '/bets/report',
      },
    ],
  },
];

export const BetsPage: React.FC = () => {
  const classNames = getClassNames();

  /* We override the use of Fluent UI Link handler here to benefit the fast navigation from react-router-dom */
  const navigate = useNavigate();
  const onLinkClick = (ev?: React.MouseEvent, item?: INavLink) => {
    ev && ev.preventDefault();
    navigate(item?.url!);
  };

  return (
    <div className={classNames.root}>
      <Nav className={classNames.navigationPanel} groups={navLinkGroups} onLinkClick={onLinkClick} />
      <div className={classNames.contentWrapper}>
        <h2>My bets</h2>
        <Outlet />
      </div>
    </div>
  );
};
