import { mergeStyleSets } from "@fluentui/react";

export interface MenuStyles {
  header: string;
  menu: string;
  menuItem: string;
}

export const getClassNames = (): MenuStyles => {
  return mergeStyleSets({
    header: {
      display: 'flex',
      padding: 5,
      paddingLeft: 3,
      backgroundColor: 'rgba(0,0,0,0.9)',
      // backgroundColor: '#201f1e', // Nice black color
      fontSize: '18px',
      fontWeight: 600,
    },
    menu: {
      display: 'flex',
      listStyleType: 'none',
      flexWrap: 'wrap',
      padding: 0,
      margin: 0,
      borderLeft: '2px solid white'
    },
    menuItem: {
      display: 'inline-block',
      paddingTop: 10,
      paddingRight: 8,
      paddingBottom: 10,
      paddingLeft: 8,
      color: '#f5f5f7',
    }
  });
};