import { PrimaryButton, TextField } from "@fluentui/react";
import { useState } from "react";
import { Arrow } from "./Arrow";
import { Line } from "./Line";
import { getClassNames } from "./WallPageStyles";

const timelineItem = (authorImageUrl: string, authorDisplayName: string, postDate: string, postContent: string) => {
  const classNames = getClassNames();

  return (
    <div className={classNames.timelineItem}>
      <img className={classNames.avatar} src={authorImageUrl} alt={'avatar'} />
      <Arrow />
      <div className={classNames.timelineCommentWrapper}>
        <div className={classNames.timelineCommentHeader}><strong>{authorDisplayName}</strong> wrote on {postDate}.</div>
        <div className={classNames.timelineCommentContent}>{postContent}</div>
      </div>
    </div>);
};

export const WallPage: React.FC = () => {
  const classNames = getClassNames();

  const [commentValue, setCommentValue] = useState('');

  const posts: JSX.Element[] = [];
  for (let i = 0; i < 50; i++) {
    posts.push(timelineItem('images/kangourou.png', 'Mathis', '22/08/2022', 'Louise est belle.'));
  }
  return (
    <div className={classNames.root}>
      <h2>My love wall</h2>
      <div className={classNames.timelineContainer}>
        <Line />
        <div>
          {timelineItem('images/kangourou.png', 'Mathis', '22/08/2022', 'Louise est belle.')}
          {timelineItem('images/kangourou.png', 'Mathis', '28/08/2022', 'Malta is sunny.')}
          {timelineItem('images/kangourou.png', 'Mathis', '30/08/2022', 'Venise is beautiful. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.')}

          {/* Write comment */}
          <div className={classNames.writeItem}>
            <img className={classNames.avatar} src={'images/kangourou.png'} alt={'avatar'} />
            <Arrow />
            <div className={classNames.timelineCommentWrapper}>
              <div className={classNames.timelineCommentHeader}>Write</div>
              <div className={classNames.timelineCommentContent}>
                <TextField multiline autoAdjustHeight placeholder="Say what's on your mind" onChange={(_, newValue) => setCommentValue(newValue || '')} value={commentValue} />
                <div className={classNames.writeItemCommandContainer}>
                  <PrimaryButton text="Post" onClick={() => undefined} allowDisabledFocus disabled={commentValue === ''} checked={true} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};