import { mergeStyleSets } from "@fluentui/react";

export interface GalleryStyles {
  root: string;
  headerStack: string;
  title: string;
  list: string;
}

export const getClassNames = () => {
  return mergeStyleSets({
    root: {
      paddingLeft: 10,
      backgroundColor: '#fbfbfd',
    },
    headerStack: {
      alignItems: 'center',
    },
    title: {
      textAlign: 'center',
      fontSize: 36,
      fontFamily: 'monospace',
    },
    list: {
      display: 'flex',
      flexWrap: 'wrap',
    }
  });
};