import { Icon, IconButton, MessageBar, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import * as React from 'react';
import { Memory } from '../../models/memory';
import { MemoryContext, MemoryProvider } from '../../providers/MemoryProvider';
import { getClassNames } from './GalleryStyles';
import { ImageSet } from './ImageSet';
import { MemoryPreview } from './MemoryPreview';
import * as uuid2 from 'uuid';
import { MessageBarContext } from '../../services/MessageBarService';

function getNewGuid(): string {
  return uuid2.v4();
}

const GalleryInternal: React.FC = () => {
  const classNames = getClassNames();

  const { messageBarProps, setMessageBarProps } = React.useContext(MessageBarContext);
  const memoryContext = React.useContext(MemoryContext);

  const closeButtonProps = { iconName: 'Add' };

  const addMemory = () => {
    // const id = context.memories.length.toString();
    const id = getNewGuid();
    const newMemory: Memory = {
      id: id,
      pathToImage: 'images/kangourou.png',
      label: 'Best road',
      date: 'One sunny day',
      spot: 'Not far from aligators',
    };
    memoryContext.addMemory.operation(newMemory);
  };

  const isLoading = memoryContext.loadMemories.isOngoing;

  return (
    <div className={classNames.root}>
      {messageBarProps && <MessageBar {...messageBarProps} onDismiss={() => setMessageBarProps(undefined)} />}
      <Stack className={classNames.headerStack} gap='10' horizontal>
        <div className={classNames.title}>My trip to Australia</div>
        <Icon iconName='Airplane' />
      </Stack>
      
      <ImageSet paths={['images/kangourouPane.png', 'images/kangourou.png']} />
      <IconButton iconProps={closeButtonProps} onClick={() => addMemory()} />
      {isLoading && <Spinner size={SpinnerSize.large} label={'Refreshing the memories...'} />}
      <div className={classNames.list}>
        {memoryContext.memories.map(memory =>
          <MemoryPreview key={memory.id} {...memory} />)
        }
      </div>
    </div>
  );
};

export const Gallery: React.FC = (props) => {
  return (
    <MemoryProvider>
      <GalleryInternal {...props} />
    </MemoryProvider>
  );
};