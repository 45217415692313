import { mergeStyleSets } from "@fluentui/react";

export interface MemoryPreviewStyles {
  root: string;
  spinner: string;
  image: string;
  descriptionContainer: string;
  title: string;
  timeLabel: string;
  locationLabel: string;
}

export const getClassNames = (): MemoryPreviewStyles => {
  return mergeStyleSets({
    root: {
      margin: 15,
      // backgroundColor: '#b9f9ff78',
      backgroundColor: '#f5f5f7',
      borderRadius: 18,
      padding: 13,
      width: 350,
      // height: 300,
    },
    spinner: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    image: {
      margin: 8,
      borderRadius: 4,
    },
    descriptionContainer:{
      margin: 10,
      fontFamily: 'cursive',
    },
    title: {
      color: '#66182a',
    },
    timeLabel: {
      color: '#6e6e73',
    },
    locationLabel: {
      color: '#6e6e73',
    },
  });
};