import { mergeStyleSets } from "@fluentui/react";

export const staticClassNames = {
  zoomRoot: 'zoom-root',
  zoomChild: 'zoom-child',
};

export interface ImageSetStyles {
  zoomRoot: string;
  zoomChild: string;
}

export const getClassNames = () => {
  return mergeStyleSets({
    root: [staticClassNames.zoomRoot, {
      // background: 'red',
      // width: 1000
      maxWidth: 416,
    }],
    child: [
      staticClassNames.zoomChild,
      {
        background: 'green',
        position: 'absolute',
        visibility: 'hidden',
        // transition: 'transform .2s',
        [`.${staticClassNames.zoomRoot}:hover &`]: {
          visibility: 'visible',
          // transform: 'scale(1.5)',
        },
      }
    ],
  });
};
