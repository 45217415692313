import { IMessageBarProps } from '@fluentui/react/lib/MessageBar';
import React from 'react';

interface MessageBarContextProps {
    messageBarProps: IMessageBarProps | undefined;
    setMessageBarProps: (message: IMessageBarProps | undefined) => void;
}

const defaultContext: MessageBarContextProps = { messageBarProps: undefined, setMessageBarProps: () => { throw new Error('Unreachable code'); } };

export const MessageBarContext = React.createContext<MessageBarContextProps>(defaultContext);

export const MessageBarService: React.FC = ({ children }) => {
    const [messageBarProps, setMessageBarProps] = React.useState<IMessageBarProps | undefined>(undefined);

    return (
        <MessageBarContext.Provider value={{ messageBarProps, setMessageBarProps }}>
            {children}
        </MessageBarContext.Provider>
    );
};