import { mergeStyleSets } from "@fluentui/react";

export interface WallPageStyles {
    root: string;
    timelineContainer: string;
    timelineItem: string;
    timelineCommentWrapper: string;
    avatar: string;
    timelineCommentHeader: string;
    timelineCommentContent: string;
    writeItem: string;
    writeItemCommandContainer: string;
}

export const getClassNames = (): WallPageStyles => {
    return mergeStyleSets({
        root: {
            paddingLeft: 20,
            paddingRight: 20,
        },
        timelineContainer: {
            display: 'flex',
            height: '100%',
            marginLeft: 40,
            paddingLeft: 16,
            marginBottom: 16,
        },
        timelineItem: {
            display: 'flex',
            marginLeft: -72,
            paddingBottom: 32,
        },
        timelineCommentWrapper: {
            flex: 'auto',
            border: '1px solid #d0d7de',
            borderRadius: '6px',
            backgroundColor: '#ffffff',
        },
        avatar: {
            width: 40,
            height: 40,
            objectFit: 'cover',
            borderRadius: '50% !important',
        },
        timelineCommentHeader: {
            paddingLeft: 16,
            paddingRight: 16,
            paddingTop: 8,
            paddingBottom: 8,
            lineHeight: 21,
            borderTopLeftRadius: 6,
            borderTopRightRadius: 6,
            borderBottom: '1px solid #d0d7de',
            backgroundColor: '#f6f8fa',
        },
        timelineCommentContent: {
            padding: 16,
        },
        writeItem: {
            display: 'flex',
            marginLeft: -72,
        },
        writeItemCommandContainer: {
            display: 'flex',
            justifyContent: 'end',
            marginTop: 8,
        },
    });
}